.LoginContainer {
    background: #F5F6F8;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .Title {
    margin-top: 35vh;
    text-align: center;
    top: 50px;
  }
  
  .Authenticate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    align-self: center;
  }
  
  h4 {
    color: white;
  }
  h3 {
    color: white;
  }