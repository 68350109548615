.DashboardContainer {
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Search {
    width: 100vw;
    background: linear-gradient(150deg, #5B3790, #077D8E);
    height: 110px;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    justify-content: center;
    align-items: center;
}

.SearchHeader {
    width: 100vw;
    background: linear-gradient(220deg, #5B3790, #077D8E);
    height: 110px;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    justify-content: center;
    align-items: center;
}

.FlatHeader {
    width: 100vw;
    background: linear-gradient(220deg, #5B3790, #077D8E);
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Content {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
