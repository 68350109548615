.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .DataActionsBar {
    background: #e6eef1;
    height: 100px;
    width: 100%;
    top:5px;
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .DataActionsBarButton {
    height: 100%;
    width: 20%;
    margin: 0 -15%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
  }
  
  .DataActionsBarIcon {
    background: white;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    border: 10px solid #e6eef1;
    box-sizing: content-box;
  }
  
  .DataActionsBarIcon-Side {
    height: 45px;
    width: 45px;
    line-height: 60px;
    margin-top: -10px;
  }
  
  .DataActionsBarIcon-Middle {
    height: 45px;
    width: 45px;
    line-height: 55px;
    margin-bottom: -5px;
  }
  
  .DataActionsBarText {
    margin: 0;
    font-size: 13px;
    text-align: center;
  }